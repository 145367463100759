<template>
  <main>
    <section class="container-fluid">
      <Navbar black active="news" />
    </section>
    <div class="services container mt-5">
      <div class="row">
        <div class="col">
          <h2>
            {{ $route.params.item.title }}
          </h2>
        </div>
      </div>
      <div class="row mt-4 gx-5">
        <div class="col">
          <h4 class="services__title mb-4"></h4>
          <p class="services__paragraph">
            {{ $route.params.item.content }}
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col"></div>
      </div>
      <div class="row mt-5"></div>
    </div>
    <Footer />
  </main>
</template>

<script>
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';

export default {
  metaInfo() {
    const title = this.$t('mainTitle');
    return {
      title,
      titleTemplate: '%s | atcbrok.kz'
    };
  },
  name: 'Services',
  components: {
    Navbar,
    Footer
  }
};
</script>

<style scoped lang="scss">
.services {
  &__paragraph {
    font-size: 1.25rem;
  }

  &__title {
    font-family: 'Fira Sans', sans-serif;
  }

  &__list {
    &-item {
      font-size: 1.25rem;
    }
  }
}
</style>
